<template>
	<div class="activity">
		<div class="home_center">
			<div class="">
				<div class="center_left">
					<div class="left_top">
						<div class="top_name"><!-- 活动中心 -->{{$t('header.HDZX')}}</div>
						
							<el-input :placeholder="$t('USEKEYWORD')" class="top_input"  v-model="listData.keyword" clearable @keyup.enter.native="" @input="search"></el-input>
						
						
						<div class="top_btn" v-if="status==1">
							<el-badge v-model="count.progressCount"  :hidden="count.progressCount==0">
								<el-button class="" :class="listData.create_search_method==0?'btn1':'btn2'" type="primary" @click="listData.create_search_method=0"><!-- 进行中 -->{{$t('job.JXZ')}}</el-button>
							</el-badge>
							<el-badge v-model="count.endCount" :hidden="count.endCount==0">
								<el-button :class="listData.create_search_method==1?'btn1':'btn2'"  type="primary" @click="listData.create_search_method=1"><!-- 已结束 -->{{$t('activity.YJS')}}</el-button>
							</el-badge>
							
							<!-- <el-button :class="listData.create_search_method==item.data_code?'btn1':'btn2'" :type="listData.create_search_method==item.data_code?'primary':''" v-for="item in createSearch" :key="item.data_code" @click="listData.create_search_method=item.data_code">{{item.data_value}}</el-button> -->
						</div>
						<div class="top_btn" v-if="status==3333">
							<el-badge v-model="count.joinCount" :hidden="count.joinCount==0">
								<el-button class="" :class="listData.join_search_method==0?'btn1':'btn2'" type="primary" @click="listData.join_search_method=0"><!-- 已报名 -->{{$t('activity.YBM')}}</el-button>
							</el-badge>
							<el-badge v-model="count.entryCount" :hidden="count.entryCount==0">
								<el-button :class="listData.join_search_method==1?'btn1':'btn2'"  type="primary" @click="listData.join_search_method=1"><!-- 已参加 -->{{$t('YCJ')}}</el-button>
							</el-badge>
							<!-- <el-button :class="listData.join_search_method==item.data_code?'btn1':'btn2'" :type="listData.join_search_method==item.data_code?'primary':''" v-for="item in joinSearch" :key="item.data_code" @click="listData.join_search_method=item.data_code">{{item.data_value}}</el-button> -->
						</div>
						<div class="top_btn" v-if="status==4">
							<el-badge v-model="count.createdCount" :hidden="count.createdCount==0">
								<el-button class="" :class="listData.history_search_method==0?'btn1':'btn2'" type="primary" @click="listData.history_search_method=0"><!-- 由你创建 -->{{$t('activity.YNCJ')}}</el-button>
							</el-badge>
							<el-badge v-model="count.joinCount" :hidden="count.joinCount==0">
								<el-button :class="listData.history_search_method==1?'btn1':'btn2'"  type="primary" @click="listData.history_search_method=1"><!-- 我参与的 -->{{$t('activity.WCYD')}}</el-button>
							</el-badge>
							<!-- <el-button :class="listData.history_search_method==item.data_code?'btn1':'btn2'" :type="listData.history_search_method==item.data_code?'primary':''" v-for="item in historySearch" :key="item.data_code" @click="listData.history_search_method=item.data_code">{{item.data_value}}</el-button> -->
						</div>
					</div>
					<div class="left_box">
						<div class="left_left">
							<el-button @click="tab(1)" :class="{btn1:status==1,btn2:status!=1}" type="primary"><!-- 由你创建 -->{{$t('activity.YNCJ')}}</el-button>
							<el-button @click="tab(2)" :class="{btn1:status==2,btn2:status!=2}" type="primary"><!-- 感兴趣的 -->{{$t('activity.GXQ')}}</el-button>
							<el-button @click="tab(3)" :class="{btn1:status==3,btn2:status!=3}" type="primary"><!-- 参与的 -->{{$t('add_activity.CYD')}}</el-button>
							<el-button @click="tab(4)" :class="{btn1:status==4,btn2:status!=4}" type="primary"><!-- 往期活动 -->{{$t('add_activity.WQHD')}}</el-button>
							<router-link to="/add_activity">
								<el-button class="btn2 mt20" type="primary"><!-- 发布活动 -->{{$t('add_activity.FBHD')}}</el-button>
							</router-link>
							
						</div>
						<div class="left_center" v-loading="loading"  element-loading-background="rgba(0, 0, 0, 0.8)">
							
							<div class="center_list" v-for="(item,index) in list" :key="item.event_id">
								<router-link :to='"/activity_detail?event_id="+item.event_id'>
								<img :src="item.listpics[0]" class="list_img" alt="">
								</router-link>
								<div class="list_right">
									<div class="right_title">
										<div class="title">
											{{item.event_name}}
										</div>
										<div class="title_btn">
											<!-- <el-button class="btn1" type="primary">
												<img src="../../assets/img/like_fill.png" class="like" alt="">
												感兴趣
											</el-button>
											<el-button class="btn1" type="primary">已报名</el-button> -->
											<template v-if="status==1">
												
													<el-button class="btn1" type="primary" @click.stop="guanli(item.event_id)"><!-- 管理活动 -->{{$t('activity.GLHD')}}</el-button>
												
												
													<el-button class="btn1" type="primary" @click.stop="bianji(item.event_id)"><!-- 编辑活动 -->{{$t('activity.BJHD')}}</el-button>
												
											</template>
											<!-- <template v-if="status==2">
												<el-button class="btn1" type="primary" @click="collect(item.event_id,index)">
													<img src="../../assets/img/like_fill.png" class="fengx" alt="">感兴趣
												</el-button>
												<el-button class="btn1" type="primary" @click="join(item.event_id,index)">参加</el-button>
											</template> -->
											<template v-if="status==2||status==3">
												<el-button class="btn1" type="primary" @click.stop="collect(item.event_id,index)">
													<img src="../../assets/img/like_fill.png" v-if="item.is_collect==1" class="fengx" alt="">
													<img src="../../assets/img/like2.png" v-else class="fengx" alt=""><!-- 感兴趣 -->{{$t('activity.GXQ')}}
												</el-button>
												<el-button class="btn1" type="primary" @click.stop="join(item.event_id,index)">{{item.is_join==1?$t('QXBM'):$t('BM')}}</el-button>
											</template>
											<el-button class="btn2" type="primary" @click.stop="">
												<img src="../../assets/img/fengx.png" class="fengx" alt="">
												<div class="sharemenu">
													<div @click.stop="share(item.event_id,'facebook',item.event_name)">Facebook</div>
													<div @click.stop="share(item.event_id,'Linkedin',item.event_name)">LinkedIn</div>
													<div @click.stop="share(item.event_id,'twitter',item.event_name)">Twitter</div>
												</div>
											</el-button>
										</div>
									</div>
									<div class="right_time">
										<!-- 活动时间 -->{{$t('activity.HDSJ')}}：{{$util.rTime(item.event_start_time)}}
									</div>
									<div class="right_msg">
										{{item.event_desc}}
									</div>
									<div class="right_last">
										<div class="last">
											<img src="../../assets/img/like_fill.png" class="last_img" alt="">
											{{item.collect_count}}人
										</div>
										<div class="last">
											<img src="../../assets/img/user.png" class="last_img" alt="">
											{{item.join_num_people}}人
										</div>
									</div>
								</div>
							</div>
							
						</div>
					</div>
					
				</div>
				
			</div>

			<!-- 消息列表 -->
			<Homeright></Homeright>
		</div>
		
	</div>
</template>

<script>
	import Homeright from '../../components/home/home_right.vue';
	import scroll from '@/utils/scroll.js';
	import {debounce} from '@/utils/debounce.js';
	export default {
		components: {
			Homeright,
		},
		data() {
			return {
				input:'',
				listData:{
					keyword:'',					
					pageSize:15,
					currentPage:1,
					create_search_method: 0,
					join_search_method: 0,
					history_search_method: 0,
				},
				isLast:0,
				list:[],
				status:1,
				loading:false,
				createSearch:[],
				joinSearch:[],
				historySearch:[],
				count:{}
			};
		},
		created() {
			if(this.$route.query.status){
				this.status=this.$route.query.status;
			}
			if(this.$route.params.status){
				this.status=this.$route.params.status;
			}
			
			this.getUnit();
			if(this.status!=2){
				this.getCount();
			}
			this.getList();
			scroll.scrollToBottom(this.getList);
		},
		watch:{
			'listData.create_search_method':{
				handler:function(nval,oval){
					this.getList('reload');
				}
			},
			'listData.join_search_method':{
				handler:function(nval,oval){
					this.getList('reload');
				}
			},
			'listData.history_search_method':{
				handler:function(nval,oval){
					this.getList('reload');
				}
			}
		},
		methods:{
			share:function(id,type,title){
				var href=this.$config.website+'activity_detail?event_id='+id;				
				this.$util.share(href,type,title);
			},
			bianji(id){
				this.$router.push({path:'/edit_activity',query:{event_id:id}});
			},
			guanli(id){
				this.$router.push({path:'/activity_admin',query:{event_id:id}});
			},
			//获取统计
			async getCount(){
				let urls={1:'/api/event/createdSearchStat',3:'/api/event/joinSearchStat',4:'/api/event/historySearchStat'};
				let res=await this.$request.get(urls[this.status]);
				this.count=res.data;
			},
			//获取字典数据
			async getUnit(){
				let res=await this.$request.get('/api/sysdict/getDictList');
				this.createSearch=res.data.create_search_method.data;
				this.joinSearch=res.data.join_search_method.data;
				this.historySearch=res.data.history_search_method.data;
				
			},
			//收藏
			async collect(id,index){
				await this.$request.get('/api/event/collectEvent',{event_id:id});
				this.list[index].is_collect=this.list[index].is_collect==0?1:0;
			},
			//参加
			async join(id,index){
				let res=await this.$request.get('/api/event/joinEvent',{event_id:id});
				this.list[index].is_join=this.list[index].is_join==0?1:0;
			},
			//切换列表
			tab(n){			
				this.status=n;				
				this.getList('reload');
				if(this.status!=2){
					this.getCount();
				}
			},
			search:debounce(function(e){
				console.log(e);
				this.getList('reload');
			}),
			//获取工作
			  async getList(t){
				 
				if(t=='reload'){
					this.listData.currentPage=1;
					this.isLast=0;		  	
				}
				if(this.isLast==1||this.loading){
					return;
				}
				let urls={1:'/api/event/createdByPage',2:'/api/event/collectByPage',3:'/api/event/joinByPage',4:'/api/event/historyByPage'};
				this.loading=true;
				let res=await this.$request.post(urls[this.status],this.listData);
				this.loading=false;
				
				if(t=='reload'){
					this.list=res.data.records;
				}else{
					if(res.data.records.length>0){
						this.list=[...this.list,...res.data.records];
					}
				}
				if(this.listData.currentPage>=res.data.totalPage){
					this.isLast=1;
				}else{
					this.listData.currentPage+=1;
				}
				
			  }
			
		}
	};
</script>

<style lang="less" >
	.activity {
		min-height: 100%;
		background-color: #212121;
		.home_center{
			display: flex;
			justify-content: flex-end;
		}
		.center_left {
			color: #fff;
			width: 1190px;
			margin-right: 25px;
			.left_top{
				width: 1190px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				background-color: #000000;
				padding: 15px;
				box-sizing: border-box;
				.top_name{width: 320px;
					font-size: 21px;
					font-weight: bold;
				}
				.top_input{
					width: 530px;
				}
				.el-input__inner{
					background-color: #000000;
					height: 34px;
					display: flex;
					align-items: center;
				}
				.top_btn{
					.el-button{margin-left: 15px;}
					.btn2{
						background-color: #000000;
						color: #979797;
						border: 1px solid #979797;
						
					}
				}
			}
			.left_box{
				margin-top: 25px;
				display: flex;
				.left_left{
					display: flex;
					flex-direction: column;
					width: 286px;
					margin-right: 25px;
					.mt20{
						margin-top: 20px;
					}
					.btn1{
						width: 286px;
						background-color: #567BB6;
						border: none;
						
						line-height: 1.2;
						
					}
					.btn2{
						width: 286px;
						background-color: #212121;
						border: 1px solid #567BB6;
						color: #fff;
						
						line-height: 1.2;
						
					}
					.el-button{
						margin-left: 0;
						margin-bottom: 12px;
						
					}
				}
				.left_center{
					width: 878px;
					.center_list{
						padding: 20px;
						background-color: #000000;
						display: flex;
						.list_img{
							width: 249px;
							height: 163px;
							margin-right: 20px;
							border-radius: 10px;
						}
						.list_right{
							width: 570px;
							.right_title{
								display: flex;
								justify-content: space-between;
								margin-bottom: 10px;
								.title{
									width: 270px;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
									color: #567BB6;
									font-size: 15px;
								}
							}
							.right_time{
								font-size: 12px;
								margin-bottom: 10px;
							}
							.right_msg{
								font-size: 12px;
								margin-bottom: 10px;
								line-height: 1.4;
								  overflow : hidden;
								  text-overflow: ellipsis;
								  display: -webkit-box;
								  -webkit-line-clamp: 4;
								  -webkit-box-orient: vertical;
							}
							.right_last{
								display: flex;
								align-items: center;
								font-size: 14px;
								.last{
									margin-right: 10px;
									display: flex;
									align-items: center;
									.last_img{
										width: 17px;
										height: 17px;
										margin-right: 5px;
									}
								}
							}
							.title_btn{
								display: flex;
								.like{
									width: 12px;
									height: 12px;
								}
								.btn1{
									min-width: 75px;box-sizing: border-box;
									padding:0 12px;
									height: 26px;
									border-radius: 7px;
									border: 1px solid #567BB6;
									background-color: #000000;
									display: flex;
									align-items: center;
									justify-content: center;
									margin-left: 10px;
								}
								.fengx{
									width: 15px;
									height: 15px;
									vertical-align: middle;
									margin-top: -3px;
								}
								.btn2{
									width: 77px;
									height: 26px;
									border-radius: 7px;
									border: 1px solid #567BB6;
									background-color: #000000;
									display: flex;
									align-items: center;
									justify-content: center;
									margin-left: 10px;
									position:relative;
									&:hover .sharemenu{display: block;}
								}
							}
						}
					}
				}
				
			}
		}
		
		
		.center_people {
			padding: 10px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0;
			

		}
	}
</style>
